import React, { useEffect, useRef, useContext } from 'react'
import { MainContext } from "context/context"
import moment from 'moment'

import ParallaxSection from './parallaxSection'

export default ({
    background,
    category,
    title,
    excerpt,
    minutesToRead,
    date
}) => {
	const {
        addInvertedHeaderSection
    } = useContext(MainContext)

    const elementRef = useRef(null)
    
    useEffect(() => {
        if(elementRef && elementRef.current){
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    return (
        <div 
            className="blog-single-hero" 
            ref={ref => elementRef.current = ref}
        >
            <ParallaxSection backgroundUrl={background}>
                <div className="container">
                    <div className="blog-single-hero-text">
                        <div dangerouslySetInnerHTML={{ __html: category }} />
                        <h2 dangerouslySetInnerHTML={{ __html: title }} />
                        { excerpt ?
                            <p dangerouslySetInnerHTML={{ __html: excerpt }} />
                        : null }
                        <div>
                            Posted on { moment(date).format('Do MMMM, YYYY') } &bull; { minutesToRead === 0 ? 'Quick' : minutesToRead + ' min'} read
                        </div>
                    </div>
                </div>
            </ParallaxSection>
        </div>
    )
}