import React from "react"
import BlogGridPost from "components/blogGridPost"
import Button from 'components/button'
import Flickity from 'react-flickity-component'
import 'flickity/css/flickity.css'

export default ({ 
    data,
    category,
    currentId
}) => {
    const {
        edges: posts
    } = data

    const relatedPosts = []

    posts.some(( { node: post }) => {
        if(relatedPosts.length === 4){
            return true
        }

        const categorySlug = post.categories.nodes.length ? post.categories.nodes[0].slug : null

        if(categorySlug === category && post.id !== currentId){
            relatedPosts.push(post)
        }
    })

    if(relatedPosts.length < 4){
        posts.some(({ node: post }) => {
            if(relatedPosts.length === 4){
                return true
            }

            if(!relatedPosts.find(relatedPost => {
                return relatedPost.id === post.id
            }) && post.id !== currentId){
                relatedPosts.push(post)
            }
        })
    }

    let isMobile = false 

    if (typeof window != 'undefined' && window.outerWidth <= 500) {
        isMobile = true
    }

    return (
        <div className="blog-related">
            <div className="blog-grid blog-grid-related">
                <div className="container">
                    <div className="blog-related-heading">
                        <h2>
                            Related news
                        </h2>
                        <Button url="/news/" label="View all articles" />
                    </div>
                    <Flickity
                        className={'carousel'}
                        options={{
                            wrapAround: true,
                            prevNextButtons: false,
                            groupCells: 1,
                            cellAlign: isMobile ? 'center' : 'left',
                            pageDots: isMobile ? true : false,
                            adaptiveHeight: true
                        }}
                        reloadOnUpdate
                        static
                    >
                        { relatedPosts.map((row, index) => (
                            <BlogGridPost post={row} key={index} />
                        ))}
                    </Flickity>
                </div>
            </div>
        </div>
    )
}